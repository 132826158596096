<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.knowledgeBase.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.knowledgeBase.list.title"></app-i18n>
      </h1>

      <app-knowledge-base-list-toolbar></app-knowledge-base-list-toolbar>
      <app-knowledge-base-list-filter></app-knowledge-base-list-filter>
      <app-knowledge-base-list-table></app-knowledge-base-list-table>
    </div>
  </div>
</template>

<script>
import KnowledgeBaseListFilter from '@/modules/knowledge-base/components/knowledge-base-list-filter.vue';
import KnowledgeBaseListTable from '@/modules/knowledge-base/components/knowledge-base-list-table.vue';
import KnowledgeBaseListToolbar from '@/modules/knowledge-base/components/knowledge-base-list-toolbar.vue';

export default {
  name: 'app-knowledge-base-list-page',

  components: {
    [KnowledgeBaseListFilter.name]: KnowledgeBaseListFilter,
    [KnowledgeBaseListTable.name]: KnowledgeBaseListTable,
    [KnowledgeBaseListToolbar.name]: KnowledgeBaseListToolbar,
  },
};
</script>

<style>
</style>
