import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import FilesField from '@/shared/fields/files-field';
import StringField from '@/shared/fields/string-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';

function label(name) {
  return i18n(`entities.knowledgeBase.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  issue: new StringField('issue', label('issue'), {
    required: true,
    max: 2000,
  }),
  document: new FilesField(
    'document',
    label('document'),
    Storage.values.knowledgeBaseDocument,
    Permissions.getFilePermissions('knowledgeBaseFileFields', 'document'),
    {
      required: false,
      size: 10485760,
      formats: [
        "pdf",
        "doc",
        "docx",
        "xlsx",
        "xltx",
        "xltm",
      ]
    }),
  tag: new StringField('tag', label('tag'), {
    required: true,
    max: 255,
  }),
  resolution: new StringField('resolution', label('resolution'), {
    required: true,
    max: 2000,
  }),

  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export class KnowledgeBaseModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
