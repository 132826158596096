<template>
  <el-form
    :label-position="labelPosition"
    :label-width="labelWidthFilter"
    :model="model"
    :rules="rules"
    @submit.native.prevent="doFilter"
    class="filter"
    ref="form"
  >
    <el-row>
      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.issue.label"
          :prop="fields.issue.name"
        >
          <el-input v-model="model[fields.issue.name]" />
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.resolution.label"
          :prop="fields.resolution.name"
        >
          <el-input v-model="model[fields.resolution.name]" />
        </el-form-item>
      </el-col>

       <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.tag.label"
          :prop="fields.tag.name"
        >
          <el-input v-model="model[fields.tag.name]" />
        </el-form-item>
      </el-col>
    </el-row>

    <div class="filter-buttons">
      <el-button
        :disabled="loading"
        @click="doFilter"
        icon="el-icon-fa-search"
        type="primary"
      >
        <app-i18n code="common.search"></app-i18n>
      </el-button>

      <el-button
        :disabled="loading"
        @click="doResetFilter"
        icon="el-icon-fa-undo"
      >
        <app-i18n code="common.reset"></app-i18n>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { KnowledgeBaseModel } from '@/modules/knowledge-base/knowledge-base-model';

const { fields } = KnowledgeBaseModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.issue,
  fields.resolution,
  fields.tag,
]);

export default {
  name: 'app-knowledge-base-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'knowledgeBase/list/loading',
      filter: 'knowledgeBase/list/filter',
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(
      this.filter,
      this.$route.query,
    );

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'knowledgeBase/list/doReset',
      doFetch: 'knowledgeBase/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style>
</style>
